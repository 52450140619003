
export default {
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: true,
    },

    checked: {
      type: Boolean,
      required: true,
    },

    userPreferencesProperty: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      checkedInternal: this.checked,
    };
  },

  watch: {
    checkedInternal: function () {
      this.$emit("change", this.checkedInternal, this.userPreferencesProperty);
    },
  },
};
