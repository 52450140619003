// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_CpHCN{display:flex}.container_CpHCN:not(:last-child){margin-right:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_CpHCN"
};
module.exports = ___CSS_LOADER_EXPORT___;
